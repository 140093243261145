import React from 'react';

import {
  Col,
  Row,
} from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

const envVarFlavor = process.env.REACT_APP_FLAVOR || 'default';

const ModavestIcon = require(`@assets/icons/${envVarFlavor}/icon.svg`).default;

const Loading: React.FC = () => {
  return (
    <>
      <Row align="middle" style={{ height: '100vh' }}>
        <Col span={24} className="d-flex align-center justify-center" style={{ flexDirection: 'column' }}>
          <img src={ModavestIcon} className="mb-6" width="40" height="40" />

          <div className="mb-4" style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
            <LoadingOutlined
              type="play-circle-o"
              style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '2em' }}
            />
          </div>
          <p>Carregando...</p>
        </Col>
      </Row>
    </>
  );
};

export default Loading;

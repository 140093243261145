import { StateTax } from '@components/StateTaxesForm/models/state-tax-form-model';

import * as api from './api';

export async function get() {
  return api.get(`state-taxes`);
}

export async function post(stateTaxes: StateTax): Promise<any> {
  try {
    return api.post(`state-taxes`, stateTaxes).catch((error) => {
      console.log('no update', error, error.response);
      return Promise.resolve(error.response);
    });
  } catch (error) {
    throw error;
  }
}

import { Steps, Typography } from 'antd';
import React, { useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { useBrand } from '@hooks/BrandContext';
import { useGrid } from '@hooks/GridContext';
import { useReference } from '@hooks/ReferenceContext';
import { Color } from '@models/Color';
import { Composition } from '@models/Composition';
import { Detail } from '@models/Detail';
import { ImageColorReferences } from '@models/ImageColorReferences';
import { Reference } from '@models/Reference';
import { v4 as uuidv4 } from 'uuid';
import { GridAndColorForm, SharedData } from './models/product-form-models';
import ProductFormGridAndColorStep from './steps/ProductFormGridAndColorStep';
import ProductFormImagesStep from './steps/ProductFormImagesStep';
import ProductFormReferenceStep from './steps/ProductFormReferenceStep';
import { Container, StepsContainer } from './styles';

const { Title, Text } = Typography;
const { Step } = Steps;

const steps = [
  {
    title: 'Referência',
    content: () => null,
  },
  {
    title: 'Cores e Grade',
    content: () => null,
  },
  {
    title: 'Imagens',
    content: () => null,
  },
];

interface ProductFormProps {
  onCloseModal: Function;
  isEditing: boolean;
  referenceToEdit?: Reference;
}

const ProductForm: React.FC<ProductFormProps> = ({ onCloseModal, isEditing = false, referenceToEdit = null }) => {
  const { loadAllGrids } = useGrid();
  const { loadBrands } = useBrand();
  const { createReferenceWithIntegration, loadReferenceDetails, currentReference } = useReference();
  const [currentStep, setCurrentStep] = useState(0);

  const [loading, setLoading] = useState<boolean>(false);

  const [referenceFormValues, setReferenceFormValues] = React.useState<Reference | any>();
  const [gridAndColorsFormValues, setGridAndColorsFormValues] = React.useState<GridAndColorForm | any>();
  const [imagesFormValues, setImagesFormValues] = React.useState<GridAndColorForm | any>();
  const [sharedData, setSharedData] = React.useState<SharedData | any>();

  const [activeStep, setActiveStep] = useState(0);

  React.useEffect(() => {
    loadAllGrids();
    loadBrands();

    if (isEditing) {
      const loadDataToEdit = async () => {
        setLoading(true);
        await loadReferenceDetails(referenceToEdit?.ReferenceCode);
        setLoading(false);
      };

      loadDataToEdit();
    }
  }, []);

  React.useEffect(() => {
    if (currentReference && isEditing) {
      const products = currentReference?.colors?.map((color) => color.products).flat();

      const colors =
        currentReference?.colors.map((color) => {
          return {
            key: uuidv4(),
            name: color?.name,
            code: color?.code,
            imageColorReferences: color?.imageColorReferences || [],
          };
        }) || [];

      let sizes: any =
        products?.map((product) => {
          return {
            key: uuidv4(),
            value: product?.size,
            isActive: product?.isActive,
            barCodes: product?.barCodes || [],
            productCode: product?.code,
          };
        }) || [];

      const splitedSizes = splitArray(sizes, currentReference?.grid?.length);

      const productsPerTab =
        colors?.map((color, index) => {
          return {
            color: {
              key: color?.key,
              name: color?.name,
              code: color?.code,
            },
            sizes: splitedSizes[index].map((size, innerIndex) => {
              return {
                ...size,
                key: splitedSizes[0][innerIndex]?.key,
              };
            }),
            selectedRowKeys: splitedSizes[0]?.filter((size: any) => size?.isActive)?.map((size: any) => size?.key),
          };
        }) || [];

      sizes = splitedSizes?.[0]?.map((size) => {
        return {
          key: size.key,
          value: size?.value,
          isActive: size?.isActive,
          productCode: size?.productCode,
        };
      });

      const gridAndColors = {
        colors,
        sizes,
        productsPerTab,
      };

      const images: any = {};
      colors?.forEach((color, index) => {
        if (color?.imageColorReferences) {
          color?.imageColorReferences?.forEach((image) => {
            images[index] = [
              {
                colorKey: color?.key,
                key: uuidv4(),
                sequence: image?.sequence,
                image: image?.image,
                image_medium: image?.image_medium,
                image_small: image?.image_small,
                isDefault: image?.isDefault,
              },
            ];
          });
        }
      });

      setReferenceFormValues(currentReference);
      setGridAndColorsFormValues(gridAndColors);
      setImagesFormValues(images);
      changeSharedData({ colors });
    }
  }, [currentReference]);

  function handleSetImagesFormValues(images: any) {
    setImagesFormValues(images);
  }

  function splitArray(array: any[], partSize: number) {
    const parts: any[][] = [];
    for (let i = 0; i < array.length; i += partSize) {
      parts.push(array.slice(i, i + partSize));
    }
    return parts;
  }

  const goToNextStep = () => {
    if (currentStep === 2) {
      hadleSubmit();
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const goToBackStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const changeSharedData = (value: any) => {
    if (value?.gridCode !== sharedData?.gridCode) {
      setGridAndColorsFormValues(null);
    }
    setSharedData({ ...sharedData, ...value });
  };

  const hadleSubmit = async () => {
    setLoading(true);

    const colors: Color[] = [];

    gridAndColorsFormValues?.productsPerTab?.forEach((product: any) => {
      const images: ImageColorReferences[] = [];

      Object.keys(imagesFormValues)?.forEach((key) => {
        const imagesPerColor = imagesFormValues[key]
          ?.filter((image: any) => image.colorKey === product?.color?.key)
          .map((image: any) => {
            return {
              sequence: `${image.sequence}`,
              image: image.image,
              image_small: image.image_small,
              image_medium: image.image_medium,
              isDefault: image.isDefault,
              integrationId: Number(localStorage.getItem('@modavest/integration_id')),
              ReferenceCode: referenceFormValues.ReferenceCode,
            };
          });

        if (imagesPerColor?.length > 0) images.push(imagesPerColor);
      });

      const color = {
        integrationId: Number(localStorage.getItem('@modavest/integration_id')),
        code: product?.color?.code ? product?.color?.code : product?.color?.name,
        name: product?.color?.name,
        imageColorReferences: [...images?.flat()],
        ReferenceCode: referenceFormValues.ReferenceCode,
        products: product?.sizes?.map((size: any) => {
          return {
            colorCode: product?.color?.code ? product?.color?.code : product?.color?.name,
            size: size?.value,
            isActive: size?.isActive,
            barCodes: size?.barCodes,
            ReferenceCode: referenceFormValues?.ReferenceCode,
            integrationId: Number(localStorage.getItem('@modavest/integration_id')),
            ...(size?.productCode && { code: size?.productCode }),
          };
        }),
      };

      colors.push(color as Color);
    });

    const referenceBody = {
      integrationId: Number(localStorage.getItem('@modavest/integration_id')),
      colors: colors,
      products: colors.map((color) => color?.products).flat(),
      ...referenceFormValues,
      grid: colors[0].products?.map((product) => product?.size),
      brandId: +referenceFormValues?.brandId,
      details:
        referenceFormValues?.details?.map((detail: Detail) => {
          return {
            ...detail,
            typeCode: 0,
            type: 0,
            auxiliaryType: '',
            integrationId: Number(localStorage.getItem('@modavest/integration_id')),
            ReferenceCode: referenceFormValues.ReferenceCode,
          };
        }) || [],
      composition: referenceFormValues?.composition?.map((composition: Composition) => {
        return {
          ...composition,
          percentage: Number(composition.percentage),
          integrationId: Number(localStorage.getItem('@modavest/integration_id')),
          ReferenceCode: referenceFormValues.ReferenceCode,
        };
      }),
      weight: +referenceFormValues.weight,
      height: +referenceFormValues.height,
      width: +referenceFormValues.width,
      length: +referenceFormValues.length,
      packWeight: +referenceFormValues.packWeight,
      packHeight: +referenceFormValues.packHeight,
      packWidth: +referenceFormValues.packWidth,
      packLength: +referenceFormValues.packLength,
    };

    try {
      await createReferenceWithIntegration(referenceBody);
      onCloseModal();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '50px',
        width: '100%',
      }}
    >
      <StepsContainer progressDot current={currentStep}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </StepsContainer>

      <div className="steps-content">{steps[currentStep].content}</div>

      <div className="steps-action d-flex" style={{ width: '100%' }}>
        <Container
          style={{
            background: '#fff',
            padding: '100px',
            paddingTop: '110px',
            borderRadius: '8px',
            width: '100%',
          }}
        >
          {loading && (
            <div style={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <LoadingOutlined
                type="play-circle-o"
                style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '2em' }}
              />
            </div>
          )}

          {!loading && (
            <>
              {isEditing && (
                <>
                  <ProductFormReferenceStep
                    sharedData={sharedData}
                    changeSharedData={changeSharedData}
                    formValues={referenceFormValues}
                    setFormValues={setReferenceFormValues}
                    goToNextStep={goToNextStep}
                    isEditing={isEditing}
                    render={currentStep === 0}
                  />

                  <ProductFormGridAndColorStep
                    sharedData={sharedData}
                    changeSharedData={changeSharedData}
                    formValues={gridAndColorsFormValues}
                    setFormValues={setGridAndColorsFormValues}
                    goToNextStep={goToNextStep}
                    goToBackStep={goToBackStep}
                    isEditing={isEditing}
                    render={currentStep === 1}
                  />

                  <ProductFormImagesStep
                    sharedData={sharedData}
                    formValues={imagesFormValues}
                    setFormValues={handleSetImagesFormValues}
                    goToNextStep={goToNextStep}
                    goToBackStep={goToBackStep}
                    isEditing={isEditing}
                    render={currentStep === 2}
                  />
                </>
              )}

              {!isEditing && (
                <>
                  {currentStep === 0 ? (
                    <ProductFormReferenceStep
                      sharedData={sharedData}
                      changeSharedData={changeSharedData}
                      formValues={referenceFormValues}
                      setFormValues={setReferenceFormValues}
                      goToNextStep={goToNextStep}
                      render={true}
                    />
                  ) : null}

                  {currentStep === 1 ? (
                    <ProductFormGridAndColorStep
                      sharedData={sharedData}
                      changeSharedData={changeSharedData}
                      formValues={gridAndColorsFormValues}
                      setFormValues={setGridAndColorsFormValues}
                      goToNextStep={goToNextStep}
                      goToBackStep={goToBackStep}
                      render={true}
                    />
                  ) : null}

                  {currentStep === 2 ? (
                    <ProductFormImagesStep
                      sharedData={sharedData}
                      formValues={imagesFormValues}
                      setFormValues={handleSetImagesFormValues}
                      goToNextStep={goToNextStep}
                      goToBackStep={goToBackStep}
                      render={true}
                    />
                  ) : null}
                </>
              )}
            </>
          )}
        </Container>
      </div>
    </div>
  );
};

export default ProductForm;

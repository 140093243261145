import React, { useEffect } from 'react';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import Loading from '@components/Loading';
import BudgetPage from '@pages/BudgetPage';
import DevolutionRequestPage from '@pages/DevolutionRequestPage';
import DevolutionUploadPage from '@pages/DevolutionUploadPage';
import LoginPage from '@pages/LoginPage';
import MainPage from '@pages/MainPage';
import OrderImagePage from '@pages/OrderImagesPage';
import { useKeycloak } from '@react-keycloak/web';

type RoutePageType = {
  path: string;
  component: React.FC<{}>;
};

const publicRoutes: RoutePageType[] = [
  {
    path: '/pedido/imagens',
    component: OrderImagePage,
  },
  {
    path: '/solicitar-devolucao',
    component: DevolutionRequestPage,
  },
  {
    path: '/upload-devolucao',
    component: DevolutionUploadPage,
  },
  {
    path: '/budget/:budgetId',
    component: BudgetPage,
  },
];

const signedRoutes: RoutePageType[] = [
  {
    path: '/painel',
    component: MainPage,
  },
];

const otherRoutes: RoutePageType[] = [
  {
    path: '/login',
    component: LoginPage,
  },
];

const Routes: React.FC = () => {
  const { initialized, keycloak } = useKeycloak();

  if (!initialized) {
    return <Loading />;
  }

  useEffect(() => {
    if (keycloak && initialized) {
      keycloak.onTokenExpired = () => keycloak.updateToken(600);
    }
    return () => {
      if (keycloak) keycloak.onTokenExpired = () => {};
    };
  }, [initialized, keycloak]);

  return (
    <>
      <BrowserRouter>
        <Switch>
          {(keycloak?.authenticated ? signedRoutes : otherRoutes).concat(publicRoutes).map(({ component, path }) => {
            return <Route key={component.name} path={path} component={component} />;
          })}
          <Route exact path="*">
            {keycloak?.authenticated ? <Redirect to="/painel" /> : <Redirect to="/login" />}
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default Routes;

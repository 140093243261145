import React from 'react';

import { Col, Form, Modal, Row, Select, Typography } from 'antd';

import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { states } from '@components/SalesRegionForm/models/sales-order-form-models';
import { useStateTaxes } from '@hooks/StateTaxesContext';
import { CustomButton } from '@styles/globals';

import { StateTax } from './models/state-tax-form-model';
import { Container, CustomInput } from './styles';

const { Title } = Typography;
const { Option } = Select;

interface StateTaxesFormProps {
  onCloseModal: Function;
}

const StateTaxesForm: React.FC<StateTaxesFormProps> = ({ onCloseModal }) => {
  const { createStateTax, registeringTax } = useStateTaxes();
  const [stateTaxForm] = Form.useForm();

  React.useEffect(() => {
    stateTaxForm.setFields([
      { name: 'state', value: null },
      { name: 'value', value: null },
    ]);
  }, []);

  const handleSubmit = async (values: StateTax) => {
    try {
      await createStateTax(values);
      onCloseModal();
    } catch (e) {
      console.log(e);
    }
  };

  const showModalConfirmation = async () => {
    const values: StateTax = await stateTaxForm.validateFields();

    Modal.confirm({
      title: 'Tem certeza que deseja cadastrar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Cadastrar',
      cancelText: 'Cancelar',
      onOk() {
        values.integrationId = Number(localStorage.getItem('@modavest/integration_id'));
        handleSubmit(values);
      },
    });
  };

  return (
    <>
      <div className="steps-action d-flex" style={{ width: '100%' }}>
        <Container
          style={{
            background: '#fff',
            padding: '100px',
            paddingTop: '110px',
            borderRadius: '8px',
            width: '100%',
          }}
        >
          {registeringTax ? (
            <div style={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <LoadingOutlined
                type="play-circle-o"
                style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '2em' }}
              />
            </div>
          ) : (
            <Form
              form={stateTaxForm}
              className="d-flex"
              style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}
            >
              <Row className="d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <Col span={12} style={{ paddingRight: '15px' }}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    Estado <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                  </Title>
                  <Form.Item name="state" rules={[{ required: true, message: 'Por favor, selecione um estado!' }]}>
                    <Select style={{ width: '100%' }} placeholder="Selecione um estado">
                      {states.map((state) => (
                        <Option key={state.UF} value={state.UF}>
                          {state.UF}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} style={{ paddingLeft: '15px' }}>
                  <Title style={{ display: 'flex', margin: 0, height: '30px' }} level={5}>
                    Tributos (%) <p style={{ color: 'red', paddingLeft: '5px' }}>*</p>
                  </Title>
                  <Form.Item name="taxes">
                    <CustomInput type="number" style={{ width: 'Q00%' }} placeholder="Tributos (%) " addonAfter="%" />
                  </Form.Item>
                </Col>
              </Row>

              <CustomButton type="primary" style={{ marginTop: 40, width: '100%' }} onClick={showModalConfirmation}>
                Cadastrar
              </CustomButton>
            </Form>
          )}
        </Container>
      </div>
    </>
  );
};

export default StateTaxesForm;

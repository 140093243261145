import { ArrowRightOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Col, Popconfirm, Row, Table, Typography } from 'antd';
import { useEffect, useState } from 'react';

import { useOfficialStore } from '@hooks/OfficialStoreContext';
import { FullScreenModal } from '@styles/globals';
import * as S from '../../styles';
import PriceTablesForm from '../PriceTablesForm';
import './styles.css';

const { Title } = Typography;

interface PriceTablesPanelProps {}

const PriceTablesPanel: React.FC<PriceTablesPanelProps> = () => {
  const [modalAddPriceTableIsVisible, setModalAddPriceTableIsVisible] = useState(false);
  const [priceTables, setPriceTables] = useState<any[]>([]);

  const { deletePriceTableFromOfficialStore, actualOfficialStore } = useOfficialStore();

  const onCloseAddPriceTableModal = () => setModalAddPriceTableIsVisible(false);
  const onShowAddPriceTableModal = () => setModalAddPriceTableIsVisible(true);

  useEffect(() => {
    if (Object.keys(actualOfficialStore).length) {
      setPriceTables(
        actualOfficialStore.priceTables.sort((a, b) => {
          if (a.priceTableCode > b.priceTableCode) return 1;
          if (a.priceTableCode < b.priceTableCode) return -1;
          return 0;
        }),
      );
    }
  }, [actualOfficialStore, setPriceTables]);

  const onUnbind = (priceTableCode: number, stateCode: string) => {
    deletePriceTableFromOfficialStore(actualOfficialStore.officialStoreId, priceTableCode, stateCode);
  };

  const columns = [
    {
      title: 'Estado',
      width: '20%',
      dataIndex: 'state',
      sorter: (state: any, nextState: any) => state.state.localeCompare(nextState.state),
      defaultSortOrder: 'ascend' as 'ascend',
    },
    {
      title: 'Tabela de Preço',
      render: (priceTable: any) => (
        <Popconfirm
          title={`Tem certeza que deseja desvincular a Tabela de Preço?`}
          onConfirm={() => onUnbind(priceTable.priceTableCode, priceTable.stateCode)}
          okText="Sim"
          cancelText="Não"
        >
          <strong className="strong-price-table">
            #{priceTable.priceTableCode} <ArrowRightOutlined style={{ fontSize: '10px' }} /> {priceTable.description}
          </strong>
        </Popconfirm>
      ),
    },
  ];

  return (
    <Col xs={24} sm={24} lg={24} xxl={12} className="d-flex">
      <S.Panel>
        <Row justify="space-between">
          <Col>
            <Title level={4} style={{ color: '#555' }}>
              Tabelas de Preço de vendedores e lojistas
            </Title>
          </Col>

          <Col>
            <S.CustomButton type="primary" onClick={onShowAddPriceTableModal} noMargin>
              <PlusCircleFilled /> Vincular Tabelas de Preço
            </S.CustomButton>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={priceTables}
              pagination={{ pageSize: 4 }}
              rowKey={(record, i) => {
                return `${record.officialStoreId}-${record.priceTableCode}-${i}`;
              }}
              scroll={{ x: 800 }}
            />
          </Col>
        </Row>
      </S.Panel>

      <FullScreenModal
        destroyOnClose={true}
        visible={modalAddPriceTableIsVisible}
        onOk={onCloseAddPriceTableModal}
        onCancel={onCloseAddPriceTableModal}
        footer={null}
        forceRender={false}
      >
        <PriceTablesForm />
      </FullScreenModal>
    </Col>
  );
};

export default PriceTablesPanel;

import { DragOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { ProductImagesItem, SharedData } from '@components/ProductForm/models/product-form-models';
import { useImages } from '@hooks/ImagesContext';
import { CustomButton } from '@styles/globals';
import { Modal } from 'antd';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import './style.css';

interface ProductFormImagesStepProps {
  sharedData?: SharedData;
  formValues: { [key: number]: ProductImagesItem[] };
  setFormValues: Function;
  goToNextStep: Function;
  goToBackStep: Function;
  isEditing?: boolean;
  render: boolean;
}

const ProductFormImagesStep: React.FC<ProductFormImagesStepProps> = ({
  sharedData,
  formValues,
  setFormValues,
  goToNextStep,
  goToBackStep,
  isEditing = false,
  render,
}) => {
  const { uploadImage, uploadImagesWithInput, objectToFileList } = useImages();

  const [colors, setColors] = React.useState<{ key: string; name: string; code: string }[]>([]);
  const [items, setItems] = React.useState<{ [key: number]: ProductImagesItem[] }>([]);

  const [draggingItem, setDraggingItem] = React.useState<any>(null);
  const [newItemName, setNewItemName] = React.useState<string>('');
  const [newItemImage, setNewItemImage] = React.useState<string>('');

  const [loadingStates, setLoadingStates] = React.useState<{ [key: number]: boolean }>({});

  React.useEffect(() => {
    if (formValues && Object.keys(formValues)?.length > sharedData?.colors?.length) {
      formValues = filterFormValuesByColor();
    }

    if (sharedData?.colors) {
      setColors(sharedData?.colors);
      const initializeItems: { [key: number]: ProductImagesItem[] } = [];
      sharedData?.colors?.forEach((color: any, index: number) => {
        const value = formValues !== undefined ? formValues[index] : [];
        initializeItems[index] = value;
      });

      setItems(initializeItems);
    }
  }, []);

  React.useEffect(() => {
    if (isEditing) {
      if (sharedData?.colors) {
        setColors(sharedData?.colors);
        const initializeItems: { [key: number]: ProductImagesItem[] } = [];
        sharedData?.colors?.forEach((color: any, index: number) => {
          const value = formValues !== undefined ? formValues[index] : [];
          initializeItems[index] = value;
        });

        setItems(initializeItems);
      }
    }
  }, [sharedData]);

  React.useEffect(() => {
    setFormValues(items);
  }, [items]);

  const finishStep = async () => {
    setFormValues(items);
    showModalConfirmation();
  };

  const returnStep = async () => {
    setFormValues(items);
    goToBackStep();
  };

  const showModalConfirmation = () => {
    Modal.confirm({
      title: 'Tem certeza que deseja concluir?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Concluir',
      cancelText: 'Cancelar',
      onOk() {
        goToNextStep();
      },
    });
  };

  const filterFormValuesByColor = () => {
    const filteredFormValues: { [key: number]: ProductImagesItem[] } = {};

    let newKey = 0;

    Object.keys(formValues).forEach((key) => {
      const colorKey = formValues[parseInt(key)][0]?.colorKey;
      if (colorKey && sharedData?.colors?.some((color: { key: string }) => color.key === colorKey)) {
        filteredFormValues[newKey++] = formValues[parseInt(key)];
      }
    });

    return filteredFormValues;
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, item: ProductImagesItem) => {
    setDraggingItem(item);
    e.dataTransfer.setData('text/plain', '');
  };

  const handleDragEnd = () => {
    setDraggingItem(null);
  };

  const handleDragOver = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, index: number, targetItem: ProductImagesItem) => {
    if (!draggingItem) return;

    const itemsCopy = items;
    const currentItemsCopy = [...itemsCopy[index]];

    const currentIndex = currentItemsCopy.indexOf(draggingItem);
    const targetIndex = currentItemsCopy.indexOf(targetItem);

    if (currentIndex !== -1 && targetIndex !== -1) {
      currentItemsCopy.splice(currentIndex, 1);
      currentItemsCopy.splice(targetIndex, 0, draggingItem);

      itemsCopy[index].forEach((item, index) => {
        currentItemsCopy[index].sequence = index;
      });

      itemsCopy[index] = currentItemsCopy;

      setItems(itemsCopy);
    }
  };

  const handleUpload = async (files: any, index: number) => {
    setLoadingOnIndex(index, true);

    const data = await uploadImagesWithInput(files);

    if (data?.length) {
      const currentItemsCopy = items[index] !== undefined ? [...items[index]] : [];
      let sequenceNumber = items[index]?.length > 0 ? items[index][items[index]?.length - 1]?.sequence + 1 : 0;

      data?.forEach((image: any) => {
        if (image?.large) {
          const colorKey = colors[index].key;

          const item: ProductImagesItem = {
            colorKey: colorKey,
            key: uuidv4(),
            sequence: sequenceNumber,
            image: image?.large,
            image_medium: image?.medium,
            image_small: image?.small,
            isDefault: false,
          };

          currentItemsCopy.push(item);
          sequenceNumber++;
        }
      });

      setItemsOnIndex(index, currentItemsCopy);
    }
    setLoadingOnIndex(index, false);
  };

  const setItemsOnIndex = (index: number, value: ProductImagesItem[]) => {
    setItems((prevItemsStates) => ({
      ...prevItemsStates,
      [index]: value,
    }));
  };

  const removeItemFromIndex = (itemIndex: number, colorIndex: number) => {
    const filteredItemsList = items[colorIndex]?.filter((item) => item.sequence !== itemIndex);
    for (let count = 0; count < filteredItemsList?.length; count++) {
      filteredItemsList[count].sequence = count;
    }
    setItemsOnIndex(colorIndex, filteredItemsList);
  };

  const setLoadingOnIndex = (index: number, value: boolean) => {
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [index]: value,
    }));
  };

  const isFieldLoading = (index: number) => {
    return loadingStates[index] || false;
  };

  const handleSetCover = (itemIndex: number, colorIndex: number) => {
    const itemsCopy = { ...items };

    Object.keys(items).forEach((key: any) => {
      items[key].forEach((_, index) => {
        if (index === itemIndex && +key === colorIndex) {
          itemsCopy[key][index].isDefault = true;
        } else {
          itemsCopy[key][index].isDefault = false;
        }
      });
    });

    setItems(itemsCopy);
  };

  return (
    <>
      {render && (
        <div className="d-flex" style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}>
          <h1 style={{ width: '100%', textAlign: 'left', fontWeight: 'bold' }}>Imagens do produto</h1>

          {colors?.length > 0 &&
            colors?.map((color, colorIndex) => {
              return (
                <div key={color?.key} style={{ width: '100%' }}>
                  <h2 style={{ width: '100%', textAlign: 'left' }}>Cor: {color?.name}</h2>

                  <div className="d-flex" style={{ justifyContent: 'flex-start', width: '100%', flexWrap: 'wrap' }}>
                    {items[colorIndex]?.map((item, itemIndex) => (
                      <div
                        key={item?.key}
                        className="d-flex"
                        style={{
                          flexDirection: 'column',
                          marginBottom: '20px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <div
                          key={item.key}
                          draggable="true"
                          className="d-flex"
                          onDragStart={(e) => handleDragStart(e, item)}
                          onDragEnd={handleDragEnd}
                          onDragOver={handleDragOver}
                          onDrop={(e) => handleDrop(e, colorIndex, item)}
                          style={{
                            cursor: 'grab',
                            width: '170px',
                            height: '220px',
                            marginRight: '20px',
                            marginBottom: '20px',
                            borderRadius: '20px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                          }}
                        >
                          <div className="drag-item-hover">
                            <div className="drag-item-hover-inner">
                              <DragOutlined width={40} height={40} />
                              <p style={{ margin: 0 }}>Arrastar</p>
                            </div>
                          </div>
                          <div className="close-icon" onClick={() => removeItemFromIndex(itemIndex, colorIndex)}>
                            X
                          </div>
                          <div
                            style={{
                              borderRadius: '20px',
                              width: '100%',
                              height: '100%',
                              backgroundImage: `url(${item?.image})`,
                              backgroundPosition: 'center',
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                            }}
                          ></div>
                        </div>
                        <div className="checkbox">
                          <input
                            checked={item?.isDefault}
                            onChange={() => handleSetCover(itemIndex, colorIndex)}
                            type="checkbox"
                            id={`checkbox-${item.key}`}
                            className="checkbox"
                            name={`checkbox-${item.key}`}
                          />
                          <label htmlFor={`checkbox-${item.key}`}>Capa</label>
                        </div>
                      </div>
                    ))}

                    <label
                      htmlFor={`file-upload-${colorIndex}`}
                      className="d-flex"
                      style={{
                        cursor: isFieldLoading(colorIndex) ? 'default' : 'pointer',
                        width: '170px',
                        minWidth: '170px',
                        height: '220px',
                        marginBottom: '20px',
                        borderRadius: '20px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        backgroundColor: '#E5E5E5',
                        flexDirection: 'column',
                      }}
                    >
                      {isFieldLoading(colorIndex) ? (
                        <LoadingOutlined
                          type="play-circle-o"
                          style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '2em' }}
                        />
                      ) : (
                        <>
                          <div className="add-image-icon">
                            <p style={{ margin: '0', color: 'white' }}>+</p>
                          </div>
                          <p style={{ margin: 0, fontSize: 22, textAlign: 'center' }}>Adicionar imagens</p>
                          <input
                            id={`file-upload-${colorIndex}`}
                            type="file"
                            multiple={true}
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={(e) => handleUpload(e?.target?.files, colorIndex)}
                          />
                        </>
                      )}
                    </label>
                  </div>
                </div>
              );
            })}

          <CustomButton type="primary" style={{ marginTop: 40, width: '100%' }} onClick={finishStep}>
            Concluir e ver referência
          </CustomButton>

          <CustomButton type="default" style={{ marginTop: 20, width: '100%' }} onClick={() => returnStep()}>
            Voltar
          </CustomButton>
        </div>
      )}
    </>
  );
};

export default ProductFormImagesStep;

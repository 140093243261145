import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';

import { message } from 'antd';

import { StateTax } from '@components/StateTaxesForm/models/state-tax-form-model';
import { StateTaxes } from '@models/StateTaxes';
import * as StateTaxesService from '@services/state-taxes';

interface StateTaxesState {
  loadingStateTaxes: boolean;
  stateTaxes: StateTaxes[];
  loadRequestStateTaxes: Function;
  registeringTax: boolean;
  createStateTax: Function;
}

interface StateTaxesProviderProps {
  children: ReactNode;
}

const StateTaxesContext = createContext<StateTaxesState>(Object.assign({}));

const StateTaxesProvider: React.FC<StateTaxesProviderProps> = ({ children }) => {
  const [stateTaxes, setStateTaxes] = useState<StateTaxes[]>([]);
  const [loadingStateTaxes, setLoadingStateTaxes] = useState<boolean>(false);
  const [registeringTax, setRegisteringTax] = useState<boolean>(false);

  const loadRequestStateTaxes = useCallback(async () => {
    setLoadingStateTaxes(true);
    try {
      const stateTaxes = await StateTaxesService.get();

      setStateTaxes(stateTaxes.data);
    } catch {
      message.error('Erro ao buscar tributos');
    } finally {
      setLoadingStateTaxes(false);
    }
  }, [setStateTaxes, setLoadingStateTaxes]);

  const createStateTax = useCallback(async (body: StateTax) => {
    try {
      setRegisteringTax(true);
      const { data } = await StateTaxesService.post(body);
      console.log(data);

      if (data.statusCode && (data.statusCode !== 200 || data.statusCode !== 201)) {
        throw data;
      }
      await loadRequestStateTaxes();

      message.success('Triubuto estadual cadastrado com sucesso!');
    } catch (e: any) {
      if (e?.response?.data?.message) {
        message.error(e?.response?.data?.message);
      } else {
        message.error('Erro ao criar condição de pagamento');
      }
      throw e;
    } finally {
      setRegisteringTax(false);
    }
  }, []);

  return (
    <StateTaxesContext.Provider
      value={{
        stateTaxes,
        loadingStateTaxes,
        registeringTax,
        loadRequestStateTaxes,
        createStateTax,
      }}
    >
      {children}
    </StateTaxesContext.Provider>
  );
};

const useStateTaxes = () => {
  const context = useContext(StateTaxesContext);
  return context;
};

export { StateTaxesProvider, useStateTaxes };

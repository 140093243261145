import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';

import { message } from 'antd';

import * as BudgetService from '@services/budget';

interface BudgetState {
  loadingBudget: boolean;
  budget: any;
  loadRequestBudget: Function;
}

interface BudgetProviderProps {
  children: ReactNode;
}

const BudgetContext = createContext<BudgetState>(Object.assign({}));

const BudgetProvider: React.FC<BudgetProviderProps> = ({ children }) => {
  const [budget, setBudget] = useState<any>();
  const [loadingBudget, setLoadingBudget] = useState<boolean>(false);

  const loadRequestBudget = useCallback(
    async (budgetId: string) => {
      setLoadingBudget(true);
      try {
        const budget = await BudgetService.getBudget(budgetId);

        setBudget(budget.data);
      } catch {
        message.error('Erro ao buscar orçamento');
      } finally {
        setLoadingBudget(false);
      }
    },
    [setBudget, setLoadingBudget],
  );

  return (
    <BudgetContext.Provider
      value={{
        loadingBudget,
        budget,
        loadRequestBudget,
      }}
    >
      {children}
    </BudgetContext.Provider>
  );
};

const useBudget = () => {
  const context = useContext(BudgetContext);
  return context;
};

export { BudgetProvider, useBudget };

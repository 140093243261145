import React from 'react';
import ReactDOM from 'react-dom';

import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';

import App from './App';

function updateFavicon(iconPath: string): void {
  console.log(iconPath);
  const link = document.querySelector<HTMLLinkElement>("link[rel~='icon']");
  console.log(link);
  if (link) {
    link.href = iconPath;
  } else {
    const newLink = document.createElement('link');
    newLink.rel = 'icon';
    newLink.href = iconPath;
    document.head.appendChild(newLink);
  }
}

function updateTabTitle(title: string): void {
  document.title = title == 'modavest' ? 'Modavest' : title == 'rapidsales' ? 'RapidSales' : title;
}
const envFlavor = process.env.REACT_APP_FLAVOR || 'default';
const faviconPath = `/${envFlavor}/favicon.ico`;
updateFavicon(faviconPath);
updateTabTitle(envFlavor);

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={ptBR}>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

import React, { useEffect } from 'react';

import html2pdf from 'html2pdf.js';
import { useParams } from 'react-router-dom';

import Loading from '@components/Loading';
import { useBudget } from '@hooks/BudgetContext';
import { CustomButton } from '@styles/globals';

interface RouteParams {
  budgetId: string;
}

const BudgetPage: React.FC = () => {
  const { budget, loadingBudget, loadRequestBudget } = useBudget();
  const { budgetId } = useParams<RouteParams>();

  useEffect(() => {
    console.log(budgetId);
    loadRequestBudget(budgetId);
  }, []);

  const generatePDF = () => {
    const options = {
      margin: 10,
      filename: 'orçamento.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        scale: 2,
        useCORS: true,
        allowTaint: true,
        logging: true,
      },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    html2pdf().from(budget?.html).set(options).save();
  };

  if (loadingBudget) return <Loading />;
  console.log(budget);
  return (
    <div style={{ paddingRight: 40 }}>
      <div dangerouslySetInnerHTML={{ __html: budget?.html }} />

      <CustomButton type="primary" style={{ marginTop: 20 }} onClick={generatePDF}>
        Gerar PDF
      </CustomButton>
    </div>
  );
};

export default BudgetPage;

import { OfficialStore } from '@models/OfficialStore';
import { FormInstance, Row } from 'antd';
import React from 'react';

import CollectionsPanel from '../CollectionsPanel';
import InformationsPanel from '../InformationsPanel';
import PriceTablesPanel from '../PriceTablesPanel';
import RepresentativePriceTablesPanel from '../RepresentativePriceTablesPanel';
import SalesTypePanel from '../SalesTypePanel';
import VisibilityPanel from '../VisibilityPanel';

interface OfficialStoreProfileContentProps {
  officialStore: OfficialStore;
  logoUrl: string;
  setLogoUrl: React.Dispatch<React.SetStateAction<string>>;
  form: FormInstance;
}

const OfficialStoreProfileContent: React.FC<OfficialStoreProfileContentProps> = ({
  officialStore,
  logoUrl,
  setLogoUrl,
  form,
}) => {
  return (
    <Row className="mt-6" gutter={[16, 16]}>
      <InformationsPanel officialStore={officialStore} logoUrl={logoUrl} setLogoUrl={setLogoUrl} form={form} />

      <SalesTypePanel />

      <VisibilityPanel />

      <CollectionsPanel
        officialStoreCollections={officialStore.collections}
        officialStoreId={officialStore.officialStoreId}
      />

      {officialStore.isVisibleSeller || (officialStore.isVisibleCustomer && <PriceTablesPanel />)}

      {officialStore.isVisibleRepresentative && <RepresentativePriceTablesPanel />}
    </Row>
  );
};

export default OfficialStoreProfileContent;

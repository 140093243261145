import { Form } from 'antd';
import { useEffect, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { useOrder } from '@hooks/OrdersContext';
import { Order } from '@models/Order';

interface OrderObservationsTabProps {
  order?: Order;
}

const OrderObservationsTab: React.FC<OrderObservationsTabProps> = ({ order }) => {
  useOrder();
  const [orderForm] = Form.useForm();

  const [actualOrder, setActualOrder] = useState<Order>({} as Order);

  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    setLoading(true);
    if (order?.statusOrder) {
      orderForm.setFields([{ name: 'orderStatus', value: null }]);

      if (order?.statusOrder) {
        orderForm.setFieldsValue({ orderStatus: order?.statusOrder });
      }

      setActualOrder(order);
      setLoading(false);
    }
  }, [order]);

  return (
    <>
      {loading ? (
        <div style={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <LoadingOutlined
            type="play-circle-o"
            style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '2em' }}
          />
        </div>
      ) : (
        <div>
          <table>
            <tbody>
              {actualOrder?.representativeObservations?.map((obs, index) => (
                <tr
                  key={obs.sequence}
                  style={{
                    backgroundColor: index % 2 === 0 ? 'lightgray' : 'white',
                  }}
                >
                  <td style={{ padding: '15px' }}>{obs.observation}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default OrderObservationsTab;

import './styles.css';

import React, { useEffect } from 'react';

import { Col, Row, Table } from 'antd';
import Title from 'antd/lib/typography/Title';

import { PlusCircleFilled } from '@ant-design/icons';
import HeaderButton from '@components/HeaderButton';
import StateTaxesForm from '@components/StateTaxesForm';
import { useStateTaxes } from '@hooks/StateTaxesContext';
import { FullScreenModal } from '@styles/globals';

const StateTaxesPage: React.FC = () => {
  const [modalNewProductVisible, setModalNewStateTaxesVisible] = React.useState(false);

  const { stateTaxes, loadingStateTaxes, loadRequestStateTaxes } = useStateTaxes();

  useEffect(() => {
    loadRequestStateTaxes();
  }, []);

  useEffect(() => {
    console.log(stateTaxes);
  }, [stateTaxes]);

  const onCloseNewProductModal = () => {
    setModalNewStateTaxesVisible(false);
  };

  const stateTaxesColumns = [
    {
      title: 'Estado',
      dataIndex: 'state',
    },
    {
      title: 'Taxa (%)',
      dataIndex: 'taxes',
      render: (text: string) => <>{Number(text).toFixed(2).toString().replace('.', ',')} %</>,
    },
  ];

  const showNewStateTaxesModal = () => {
    setModalNewStateTaxesVisible(true);
  };

  return (
    <>
      <Row className="mt-8" justify="space-between">
        <Col>
          <Title level={2}>Configurações de tributos</Title>
        </Col>

        <Col>
          <Row>
            <Col>
              <HeaderButton
                text="Novo tributo estadual"
                onClick={showNewStateTaxesModal}
                Icon={<PlusCircleFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
              />
            </Col>
          </Row>
        </Col>

        <FullScreenModal
          destroyOnClose={true}
          visible={modalNewProductVisible}
          onOk={onCloseNewProductModal}
          onCancel={onCloseNewProductModal}
          footer={null}
          forceRender={false}
        >
          <Row align="middle" justify="center">
            <Col span={24} className="d-flex" style={{ width: '1000px' }}>
              <StateTaxesForm onCloseModal={onCloseNewProductModal} />
            </Col>
          </Row>
        </FullScreenModal>
      </Row>
      <Table
        scroll={{ x: 800 }}
        dataSource={stateTaxes}
        columns={stateTaxesColumns}
        className="stateTaxes-table mt-8"
        loading={loadingStateTaxes}
        pagination={false}
      />
    </>
  );
};

export default StateTaxesPage;

import { Checkbox, Col, Popconfirm, Row, Table, message } from 'antd';
import React, { useCallback, useEffect } from 'react';

import './styles.css';

import { PlusCircleFilled } from '@ant-design/icons';
import HeaderButton from '@components/HeaderButton';
import ProductForm from '@components/ProductForm';
import { useIntegration } from '@hooks/IntegrationContext';
import { usePaymentConditions } from '@hooks/PaymentConditionsContext';
import { PaymentConditions } from '@models/PaymentConditions';
import { FullScreenModal } from '@styles/globals';
import Title from 'antd/lib/typography/Title';
import PaymentConditionsForm from '@components/PaymentConditionsForm';

const PaymentPage: React.FC = () => {
  const { hasMerpIntegration } = useIntegration();

  const [modalNewProductVisible, setModalNewProductVisible] = React.useState(false);

  const {
    updatePaymentConditions,
    paymentConditions,
    loadRequestPaymentConditions,
    loadingPaymentConditions,
    paymentConditionFilters,
    paymentConditionTotal,
    paymentConditionsPage,
  } = usePaymentConditions();

  useEffect(() => {
    loadRequestPaymentConditions(0);
  }, []);

  const showNewProductModal = () => {
    setModalNewProductVisible(true);
  };

  const onCloseNewProductModal = () => {
    setModalNewProductVisible(false);
  };

  const handleTogglePaymentCondition = useCallback(
    (paymentCondition: PaymentConditions) => {
      paymentCondition.isActive = !paymentCondition.isActive;
      try {
        updatePaymentConditions(
          {
            ...paymentCondition,
          },
          paymentCondition.code,
        );
        message.success('Condição de Pagamento alterada com sucesso!');
      } catch (error) {
        message.error('Falha ao salvar condição de pagamento');
        console.log(error);
      }
    },
    [updatePaymentConditions],
  );

  const paymentColumns = [
    {
      title: 'Ativo',
      dataIndex: 'isActive',
      sorter: true,
      render: (_text: any, paymentCondition: any): JSX.Element => {
        const { key, ...selectedPaymentConditions } = paymentCondition;
        return (
          <>
            <Popconfirm
              title={`Tem certeza que deseja ${
                selectedPaymentConditions.isActive ? 'desativar' : 'ativar'
              } a condição de pagamento ${selectedPaymentConditions.code}?`}
              onConfirm={() => handleTogglePaymentCondition(selectedPaymentConditions)}
              okText="Sim"
              cancelText="Não"
            >
              <Checkbox checked={selectedPaymentConditions.isActive} className="ml-4" />
            </Popconfirm>
          </>
        );
      },
    },
    {
      title: 'Cód.',
      dataIndex: 'code',
      sorter: true,
    },
    {
      title: 'Nome da Condição de Pagamento',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Parcelamento',
      dataIndex: 'installment',
      sorter: true,
    },
    {
      title: 'Pct. de desc./acrésc.',
      dataIndex: 'discountPercentage',
      sorter: true,
    },
    {
      title: 'Val. de desc./acrésc.',
      dataIndex: 'discountValue',
      sorter: true,
    },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.hasOwnProperty('column')) {
      paymentConditionFilters.field = `${sorter.field}`;
      paymentConditionFilters.order = `${sorter.order}`;
    }

    loadRequestPaymentConditions(pagination.current, paymentConditionFilters);
  };

  return (
    <>
      <Row className="mt-8" justify="space-between">
        <Col>
          <Title level={2}>Configurações Gerais de Pagamento</Title>
        </Col>

        <Col>
          <Row>
            {hasMerpIntegration && (
              <Col>
                <HeaderButton
                  text="Nova condição de pagamento"
                  onClick={showNewProductModal}
                  Icon={<PlusCircleFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
                />
              </Col>
            )}
          </Row>
        </Col>

        <FullScreenModal
          destroyOnClose={true}
          visible={modalNewProductVisible}
          onOk={onCloseNewProductModal}
          onCancel={onCloseNewProductModal}
          footer={null}
          forceRender={false}
        >
          <Row align="middle" justify="center">
            <Col span={24} className="d-flex" style={{ width: '1000px' }}>
              <PaymentConditionsForm onCloseModal={onCloseNewProductModal} />
            </Col>
          </Row>
        </FullScreenModal>
      </Row>

      <Table
        scroll={{ x: 800 }}
        dataSource={paymentConditions}
        columns={paymentColumns}
        className="payment-table mt-8"
        loading={loadingPaymentConditions}
        pagination={{
          current: paymentConditionsPage,
          total: paymentConditionTotal,
          pageSize: 10,
          showSizeChanger: false,
        }}
        onChange={handleTableChange}
      />
    </>
  );
};

export default PaymentPage;
